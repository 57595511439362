import { ILicense } from "../../db/models"
import fetchPartnerService from "../../service/fetchPartnerService"

async function getPartnerLicenses() {
  const res = await fetchPartnerService("GET", null, "general/licenses")
  return res.licenses as ILicense[]
}

async function savePartnerLicense(
  licenseName: string,
  licenseRequest: Partial<ILicense>
) {
  const res = await fetchPartnerService(
    "PATCH",
    licenseRequest,
    `general/license/${licenseName}`
  )
  return res
}

export { getPartnerLicenses, savePartnerLicense }
