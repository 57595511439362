/* eslint-disable @typescript-eslint/no-explicit-any */
import { dev_log } from "../utilities"
import * as Sentry from "@sentry/nextjs"

const PARTNER_SERVICE_URL = process.env.NEXT_PUBLIC_PARTNER_SERVICE_URL

export default async function fetchPartnerService(
  method: "GET" | "POST" | "PATCH" | "DELETE",
  body: any = null,
  path: string
): Promise<any> {
  dev_log(`⏳ [${method}] PS/${path}`, "(body)", body)
  const coverdashAuth = JSON.parse(
    localStorage.getItem("coverdash-auth") || "null"
  ) as { idToken: string }
  let headers: any = {
    Authorization: "Bearer " + coverdashAuth.idToken,
  }
  if (["POST", "PATCH"].includes(method)) {
    headers = Object.assign({}, headers, {
      "Content-Type": "application/json",
    })
  }

  const res = await fetch(`${PARTNER_SERVICE_URL}/${path}`, {
    method,
    headers: headers as HeadersInit,
    body: body ? JSON.stringify(body) : null,
  })

  const status = res.status

  if (status === 401) {
    if (window && !window.location.href.includes("serviceErrorStatus")) {
      const url = new URL(window.location.href)
      url.searchParams.append("serviceErrorStatus", "401")
      window.location.href = url.toString()
    }
    return
  }
  //
  else if ([400, 500].includes(status)) {
    if (window && !window.location.href.includes("serviceErrorStatus")) {
      const url = new URL(window.location.href)
      url.searchParams.append("serviceErrorStatus", status.toString())
      window.location.href = url.toString()
    }
    Sentry.captureException(
      new Error(`[${method}] PS/${path} returned ${status}`)
    )
    return
  }

  const json = await res.json()

  const emojiForResponse = json.error || json.Error ? "❌" : "✅"
  dev_log(`${emojiForResponse} [${method}] PS/${path}`, "(response) ", json)
  return json
}
