import {
  HomeIcon,
  ReceiptTaxIcon,
  SpeakerphoneIcon,
  SupportIcon,
  TableIcon,
} from "@heroicons/react/outline"

export const navigation = [
  { name: "Home", href: "/", icon: HomeIcon },
  // { name: "Shop Coverage", href: "/shop-coverage", icon: ShoppingCartIcon },
  // { name: "Policies", href: "/policies", icon: DocumentTextIcon },
  // {
  //   name: "Custom Properties",
  //   href: "/custom-properties",
  //   icon: CubeTransparentIcon,
  // },
  { name: "Payments", href: "/payments", icon: TableIcon },
  {
    name: "Payment Structure",
    href: "/payment-structure",
    icon: ReceiptTaxIcon,
  },
  { name: "Support", href: "/support", icon: SupportIcon },
  { name: "Admin", href: "/admin", icon: SpeakerphoneIcon },
]

export const userNavigation = [
  { name: "Edit profile", href: "/profile" },
  { name: "Change password", href: "/profile?tab=change-password" },
]

export const adminUserIds = [
  "auth0|63aa5dec3393043784817e63", // Super User [Staging]
  "auth0|63b43d60808e526995f260cd", // Super User [Production]
]
